import colors from "config/colors.json";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
  },
  loadingBox: {
    display: "flex",
    alignItems: "center",
    height: 65,
    width: "100%",
    marginTop: 16,
    backgroundColor: colors.primary,
    color: colors.textWhite,
    justifyContent: "center",
    fontWeight: 600,
    // Remove margin so text aligns well
  },
});
export default useStyles;
