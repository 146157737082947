import { Money } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import clsx from "clsx";
import { ReactComponent as EarlyPayIcon } from "components/icons/earlypay.svg";
import LogoContainer from "components/reusable/LogoContainer/LogoContainer";
import MobileHeroSection from "components/reusable/MobileHeroSection/MobileHeroSection";
import WaitingView from "components/reusable/WaitingView/WaitingView";
import { useDefaultStyles } from "components/reusable/defaultStyles";
import useCheckoutProvider from "contexts/CheckoutContext";
import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Http from "utils/Http";
import { getCheckoutPath } from "utils/PayoutUtils";
import { RouteProps } from "utils/types/Payout";
import PageHeader from "../../components/reusable/Layout/PageHeader";
import CheckoutCompleted from "./components/CheckoutCompleted";
import CheckoutFailed from "./components/CheckoutFailed";
import useStyles from "./styles";

const Item: React.FC<{ title: string; value: string | React.ReactElement }> = ({ value, title }) => {
  const defaultClasses = useDefaultStyles();
  const classes = useStyles();
  return (
    <div className={clsx(defaultClasses.flexRow, classes.item)}>
      {title}
      <strong>{value}</strong>
    </div>
  );
};

const CheckoutSummary: React.FC = () => {
  const classes = useStyles();
  const defaultClasses = useDefaultStyles();
  const { state } = useLocation();
  const { payout, bankAccount } = useCheckoutProvider();

  const [hasError, setHasError] = React.useState(false);
  const [termsAgreed, setTermsAgreed] = React.useState(false);
  const [isSuccessful, setIsSuccessful] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const { payoutId, token } = state as RouteProps;

  const onContinue = async () => {
    if (!termsAgreed) {
      return;
    }
    setIsSuccessful(false);
    try {
      setIsProcessing(true);
      await Http.post(`checkout/confirm-payout`, {
        payoutId,
        token,
      });

      setIsProcessing(false);
      setIsSuccessful(true);
    } catch (error: Error | any) {
      if (error.response?.status === 400 && error.response?.data?.payoutStatus === "BENEFICIARY_ACCEPTED") {
        setIsProcessing(false);
        setIsSuccessful(true);
      } else {
        setHasError(true);
        setIsProcessing(false);
      }
    }
  };

  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAgreed(event.target.checked);
  };

  const visitTerms = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    event.preventDefault();
    window.open(
      payout.termsAndConditions.startsWith("http://") || payout.termsAndConditions.startsWith("https://") ? payout.termsAndConditions : `https://${payout.termsAndConditions}`,
    );
  };

  if (isSuccessful) return <CheckoutCompleted />;
  if (hasError)
    return (
      <CheckoutFailed
        onFailedAction={() => {
          setHasError(false);
        }}
      />
    );

  const selectedBankAccount: string | React.ReactElement = bankAccount?.creditPartyIdentifier ?? "";

  if (!state || payout.payoutStatus !== "CREATED") {
    return <Navigate to={getCheckoutPath()} />;
  }

  return (
    <div className={defaultClasses.wrapper}>
      <MobileHeroSection />
      <div className={defaultClasses.container}>
        <div className={clsx(classes.content, defaultClasses.containerMaxWidth)}>
          <div className={classes.summaryHeader}>
            <PageHeader title="Let's get you paid today" className={clsx(defaultClasses.textPrimary)} />
            <span className={defaultClasses.textSecondary}>Skip the wait and get your payment now</span>
          </div>
          <div>
            <Item title="Payout Total (excluding fees)" value={Money.fromStorageType(payout.feeSettings.total).format()} />
            <Item title={`EarlyPay Fee (${Percentage.fromStorageValue(payout.feeSettings.fee).format()})`} value={Money.fromStorageType(payout.feeSettings.feeTotal).format()} />
            <Item title="Paid out to " value={selectedBankAccount} />
          </div>
        </div>

        <div className={classes.totalContainer}>
          <div className={defaultClasses.flexRow}>
            <div className={defaultClasses.flexRow}>
              <EarlyPayIcon width={40} height="auto" /> EARLYPAY TOTAL&nbsp;
            </div>
            <strong>{Money.fromStorageType(payout.feeSettings.earlyPayTotal).format()}</strong>
          </div>
        </div>

        <div className={clsx(classes.content, defaultClasses.containerMaxWidth)}>
          <div className={clsx(defaultClasses.flexColumn, defaultClasses.alignCenter)}>
            <div className={clsx(classes.termsContainer, defaultClasses.maxWidth)}>
              <div>
                <div
                  className="checkItem"
                  onClick={() => {
                    setTermsAgreed(!termsAgreed);
                  }}
                >
                  <div className={clsx({ innerCheckItem: true, termsChecked: termsAgreed })}></div>
                </div>
              </div>
              <input type="checkbox" id="checkbox" onChange={handleCheckBox} checked={termsAgreed} />
              <label htmlFor="checkbox">
                I have read the{" "}
                <span className={defaultClasses.textButton} onClick={visitTerms}>
                  Terms &amp; Conditions
                </span>{" "}
                & confirm to accept them
              </label>
            </div>
            <div className={clsx(classes.buttonContainer, defaultClasses.maxWidth)}>
              {isProcessing ? (
                <WaitingView useLottieLoader size="small" />
              ) : (
                <button onClick={onContinue} disabled={!termsAgreed} className={defaultClasses.button}>
                  Confirm
                </button>
              )}
            </div>
            <LogoContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutSummary;
