import colors from "config/colors.json";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  container: {
    display: "inline",
    position: "relative",

    "& span": {
      whiteSpace: "nowrap",
    },
  },
  copiedOverlay: {
    position: "absolute",
    left: 0,
    width: "100%",
    backgroundColor: colors.background + "e6",
  },
});
export default useStyles;
