import { Currency } from "@monet-money/money-type";
import Alert from "components/reusable/Alert/Alert";
import WaitingView from "components/reusable/WaitingView/WaitingView";
import React, { PropsWithChildren, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { CHECKOUT_PATH } from "routing/paths";
import Http from "utils/Http";
import { getPayoutIdFromUrl } from "utils/PayoutUtils";
import { Payout } from "utils/types/Payout";

export type CheckoutContextValue = {
  payout: Payout;
  bankAccount?: Record<string, string>;
  setBankAccount(payout: Record<string, string>): void;
  setAlertMessage(text: string): void;
};

const emptyPayout: Payout = {
  payoutId: "",
  payoutStatus: "UNDEFINED",
  phoneNumber: {
    number: "",
    countryCode: "",
  },
  payee: {
    firstName: "",
  },
  feeSettings: {
    earlyPayTotal: {
      amount: 0,
      currency: Currency.GBP,
    },
    fee: 0,
    feeTotal: {
      amount: 0,
      currency: Currency.GBP,
    },
    total: {
      amount: 0,
      currency: Currency.GBP,
    },
  },
  partner: {
    name: "UNDEFINED",
    logo: "",
    showLanding: true,
    returnUrl: "",
  },
  termsAndConditions: "",
};
export const CheckoutContext = React.createContext<CheckoutContextValue>({
  payout: emptyPayout,
  bankAccount: undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setBankAccount: () => {
    // skip lint warning
  },
  setAlertMessage: () => {
    //
  },
});

export const CheckoutProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [payout, setPayout] = React.useState<Payout>(emptyPayout);
  const [bankAccount, setBankAccount] = React.useState<Record<string, string>>();
  const [alertMessage, setAlertMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const location = useLocation();
  const payoutId = useMemo(() => getPayoutIdFromUrl, [getPayoutIdFromUrl]);

  const fetchPayout = React.useCallback(async (): Promise<void> => {
    setAlertMessage("");
    const of = await Http.get<Payout>(`checkout/payout/${payoutId}`)
      .then((response) => response.data)
      .catch((err) => {
        //the payout is not in Created State
        if (err.response?.status === 400 && err.response?.data?.payoutStatus) {
          return { ...payout, payoutStatus: err.response?.data?.payoutStatus };
        } else if (err.response.status !== 404) {
          setAlertMessage("Error fetching payout");
        }
        return payout;
      });
    setPayout(of);
    setIsLoading(false);
  }, [payoutId]);

  React.useEffect(() => {
    if (payoutId && location.pathname == CHECKOUT_PATH) {
      fetchPayout();
    } else {
      setIsLoading(false);
    }
  }, [payoutId, location.pathname]);

  if (isLoading || (payout.payoutStatus == "UNDEFINED" && alertMessage)) {
    return <WaitingView lottieColor="primary" />;
  }

  return (
    <CheckoutContext.Provider
      value={{
        payout,
        bankAccount,
        setBankAccount,
        setAlertMessage,
      }}
    >
      <Alert message={alertMessage} type="error" />
      {children}
    </CheckoutContext.Provider>
  );
};

const useCheckoutProvider = (): CheckoutContextValue => {
  return useContext(CheckoutContext);
};

export default useCheckoutProvider;
