import { desktopSize } from "components/reusable/defaultStyles";
import colors from "config/colors.json";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  popupBox: {
    position: "fixed",
    background: `${colors.black}50`,
    width: "100%",
    height: "100vh",
    top: 0,
    left: 0,
    zIndex: 999,
  },

  popupInnerBox: {
    background: colors.background,
    color: colors.textPrimary,
    position: "relative",
    width: "80%",
    margin: "0 auto",
    height: "auto",
    maxHeight: "70vh",
    marginTop: "calc(100vh - 85vh - 20px)",
    borderRadius: 5,
    padding: "32px",
    overflow: "auto",
    textAlign: "left",
    [desktopSize]: {
      maxWidth: 350,
    },
    "& h6": {
      marginBottom: 40,
    },
    "& svg": {
      marginBottom: 16,
      width: "auto",
    },
    "& button": {
      color: colors.textPrimary,
      textAlign: "center",
      borderStyle: "none",
      cursor: "pointer",
      fontWeight: "bold",
    },
  },
});
export default useStyles;
