import { desktopSize } from "components/reusable/defaultStyles";
import colors from "config/colors.json";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  mobileHero: {
    display: "flex",
    width: "100%",
    backgroundColor: colors.primaryDark,
    color: colors.textWhite,
    fontSize: 46,
    fontWeight: 600,
    lineHeight: 1,
    alignItems: "center",
    justifyContent: "space-evenly",
    gap: 16,
    padding: 32,
    minHeight: 225,

    [desktopSize]: {
      display: "none",
    },
  },
});
export default useStyles;
