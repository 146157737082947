import colors from "config/colors.json";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  phoneContainer: {
    display: "flex",
    padding: 16,
    margin: "8px 0px",
    border: `1px solid ${colors.primary}`,
    alignItems: "center",
    gap: 16,

    "& img": {
      height: 10,
      borderRadius: 2,
    },

    "& span": {
      color: colors.textSecondary,

      "& strong": {
        color: colors.textPrimary,
      },
    },
  },
  wrongNumberInfo: {
    cursor: "pointer",
    textDecoration: "underline",
    color: colors.primary,
    textAlign: "center",
    display: "block",
  },
  sendCodeLabel: {
    color: colors.textSecondary,
    margin: 0,
  },
  content: {
    maxWidth: 325,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  rightContent: {
    display: "flex",
    flexDirection: "column",
  },
});
export default useStyles;
