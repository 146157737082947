import clsx from "clsx";
import React from "react";
import useStyles from "./styles";

type AlertType = {
  type?: "error" | "success";
  message: string;
};

const Alert = ({ type, message }: AlertType) => {
  const [visible, setVisible] = React.useState(false);
  const classes = useStyles();

  const getType = React.useMemo(() => {
    switch (type) {
      case "error":
      case "success":
        return type;
      default:
        return "default";
    }
  }, [type]);
  React.useEffect(() => {
    // hide when message is empty
    if (!message) {
      setVisible(false);
      return;
    }
    // Display the message and hide after 5 secs
    setVisible(true);
    const timer = setTimeout(() => {
      setVisible(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [message]);
  if (!visible) return null;
  return (
    <div className={classes.alertContainer}>
      <div className={clsx(classes.alert, classes[getType])}>{message}</div>
    </div>
  );
};

export default Alert;
