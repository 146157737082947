import colors from "config/colors.json";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  alertContainer: {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
    top: "16px",
  },

  alert: {
    padding: 16,
    borderRadius: 5,
    boxShadow: "2px 2px 6px #00000038",
  },
  success: {
    background: colors.success,
    color: "#FFFFFF",
  },
  default: {
    background: colors.info,
    color: colors.black,
  },
  error: {
    background: colors.error + "bf", //
    border: "1px solid " + colors.error,
    color: "#FFFFFF",
  },
});
export default useStyles;
