import clsx from "clsx";
import LogoContainer from "components/reusable/LogoContainer/LogoContainer";
import WaitingView from "components/reusable/WaitingView/WaitingView";
import { useDefaultStyles } from "components/reusable/defaultStyles";
import useCheckoutProvider from "contexts/CheckoutContext";
import dayjs from "dayjs";
import * as React from "react";
import OtpInput from "react-otp-input";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import { CHECKOUT_BANK_DETAILS_PATH } from "routing/paths";
import Http from "utils/Http";
import { getCheckoutPageLink, getCheckoutPath } from "utils/PayoutUtils";
import { RouteProps } from "utils/types/Payout";
import CheckoutLayout from "../../components/reusable/Layout/CheckoutLayout";
import PageHeader from "../../components/reusable/Layout/PageHeader";
import useStyles from "./styles";

// Time in minutes
const RESEND_TIME = 2;

const CheckoutOTPVerification: React.FC = () => {
  const defaultClasses = useDefaultStyles();
  const classes = useStyles();
  const navigate = useNavigate();
  const { payout, setAlertMessage } = useCheckoutProvider();
  const { state } = useLocation();

  const { isRunning, minutes, seconds, restart } = useTimer({
    autoStart: true,
    expiryTimestamp: dayjs().add(RESEND_TIME, "m").toDate(),
  });

  const [code, setCode] = React.useState("");
  const [hasOTPError, setHasOTPError] = React.useState(false);
  const [isSendingCode, setIsSendingCode] = React.useState(false);
  const [isVerifyingCode, setIsVerifyingCode] = React.useState(false);

  const { payoutId } = state as RouteProps;

  const restartTimer = React.useCallback(() => {
    const newExpiryTimestamp = dayjs().add(RESEND_TIME, "m");
    restart(newExpiryTimestamp.toDate());
  }, []);

  const handleChange = (otpCode: string) => {
    setCode(otpCode);
    setHasOTPError(false);
  };
  const verifyCode = async () => {
    setIsVerifyingCode(true);
    setHasOTPError(false);
    setAlertMessage("");
    try {
      const { data } = await Http.post(`checkout/verify-otp`, {
        payoutId,
        verificationCode: code,
      });
      navigate(getCheckoutPageLink(CHECKOUT_BANK_DETAILS_PATH), { state: { payoutId: payoutId!, token: data.token } });
      setIsVerifyingCode(false);
    } catch (error) {
      setHasOTPError(true);
      setAlertMessage("Seems like the code you entered is not right, please re-type again or request a new one by clicking below.");
      setIsVerifyingCode(false);
    }
  };

  const resendCode = async () => {
    if (isRunning) {
      return;
    }
    setIsSendingCode(true);
    setAlertMessage("");
    try {
      await Http.post(`checkout/send-otp`, {
        payoutId,
      });
      restartTimer();
      setIsSendingCode(false);
    } catch (error) {
      setAlertMessage("📱 Resending code failed, try again.");
      setIsSendingCode(false);
    }
  };

  const disableVerifyButton = isVerifyingCode || code.length < 6;
  // return 2:00 instead of 2:0
  const fullSeconds = `0${seconds}`.slice(-2);

  if (!state) {
    return <Navigate to={getCheckoutPath()} />;
  }
  return (
    <CheckoutLayout
      leftContent={
        <div className={defaultClasses.leftContentContainer}>
          <div className={classes.content}>
            <PageHeader title="Let's get you paid today" />
            <div className={defaultClasses.showOnDesktop}>
              <LogoContainer />
            </div>
          </div>
        </div>
      }
      rightContent={
        <div className={defaultClasses.rightContentContainer}>
          <span>
            Enter code sent to{" "}
            <b>
              {payout.phoneNumber?.number?.split("-")[0]} *******{payout.phoneNumber?.number.split("-")[1]}:
            </b>
          </span>
          <OtpInput
            value={code}
            onChange={handleChange}
            numInputs={6}
            renderInput={(inputProps) => {
              return (
                <input
                  {...inputProps}
                  type="number"
                  className={clsx({
                    [classes.otpInput]: true,
                    [classes.otpError]: hasOTPError,
                  })}
                />
              );
            }}
            shouldAutoFocus={true}
            containerStyle={classes.otpContainer}
          />

          <div className={classes.inputSubText}>
            <div className={defaultClasses.body2}>
              If you have not received the code within
              {isRunning ? ` ${minutes}:${fullSeconds} ` : ` ${RESEND_TIME} minute(s) `}
              then please
            </div>

            <span
              onClick={resendCode}
              className={clsx(defaultClasses.textPrimary, defaultClasses.textButton, {
                [classes.timerRunning]: isRunning || isSendingCode,
              })}
            >
              Request New Code
            </span>
          </div>

          {isVerifyingCode ? (
            <WaitingView useLottieLoader size="small" />
          ) : (
            <button disabled={disableVerifyButton} onClick={verifyCode} className={defaultClasses.button}>
              Verify
            </button>
          )}
        </div>
      }
    />
  );
};

export default CheckoutOTPVerification;
