import { CheckoutProvider } from "contexts/CheckoutContext";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routing/Routes";

const App: React.FC = () => {
  return (
    <Router>
      <CheckoutProvider>
        <Routes />
      </CheckoutProvider>
    </Router>
  );
};

export default App;
