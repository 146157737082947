import emails from "config/emails.json";
import { createSearchParams } from "react-router-dom";
import { CHECKOUT_PATH } from "routing/paths";

export const getFormattedIban = (iban?: string) => (iban ? iban?.split("#") : ["", ""]);

export const getContactMonetLink = (payoutId?: string) => {
  return `mailto:${emails.support}?subject=[Embedded EarlyPay]` + (payoutId ? ` PayoutId: ${payoutId}` : "");
};

/**
 * Get query params from location
 * @param url string
 * @param transformKeysToLowercase boolean
 * @returns object
 */
export const getQueryParams = (url: string, transformKeysToLowercase?: boolean): Record<string, string> => {
  const queryParams: Record<string, string> = {};
  const queryString = url.split("?")[1];

  if (queryString) {
    const paramPairs = queryString.split("&");

    paramPairs.forEach((pair) => {
      const [key, value] = pair.split("=");
      queryParams[transformKeysToLowercase ? key.toLowerCase() : key] = value;
    });
  }

  return queryParams;
};

export const getPayoutIdFromUrl = getQueryParams(window.location.search, true).payoutid || "";

export const getCheckoutPath = () =>
  CHECKOUT_PATH +
  "?" +
  createSearchParams({
    payoutId: getPayoutIdFromUrl,
  }).toString();

export const getCheckoutPageLink = (path: string) => {
  return {
    pathname: path,
    search: createSearchParams({
      payoutId: getPayoutIdFromUrl,
    }).toString(),
  };
};
