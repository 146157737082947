import { ReactComponent as CopyIcon } from "components/icons/copy-outline-icon.svg";
import { useEffect, useState } from "react";
import { useDefaultStyles } from "../defaultStyles";
import useStyles from "./styles";

type CopyTextProps = {
  text: string;
};

const CopyText: React.FC<CopyTextProps> = ({ text }) => {
  const [isTextCopied, setIsTextCopied] = useState(false);
  const defaultClasses = useDefaultStyles();
  const classes = useStyles();

  const onCopy = (value: string) => {
    navigator.clipboard.writeText(value);
    setIsTextCopied(true);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isTextCopied) {
      timeout = setTimeout(() => setIsTextCopied(false), 1500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isTextCopied]);

  return (
    <div className={classes.container}>
      <span className={defaultClasses.primaryLink} onClick={() => onCopy(text)}>
        {text} <CopyIcon width={10} />
      </span>
      {isTextCopied && <span className={classes.copiedOverlay}>Copied!</span>}
    </div>
  );
};

export default CopyText;
