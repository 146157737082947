import { ReactComponent as MonetLogo } from "components/icons/logoMonet.svg";
import { useDefaultStyles } from "components/reusable/defaultStyles";
import colors from "config/colors.json";
import * as React from "react";
import useStyles from "./styles";

type PopupProps = {
  handleClose(): void;
  buttonText?: string;
  open: boolean;
  children?: React.ReactElement | React.ReactElement[];
};

const Popup: React.FC<PopupProps> = ({ handleClose, children, buttonText = "OK", open }) => {
  const classes = useStyles();
  const defaultClasses = useDefaultStyles();
  if (!open) return null;
  return (
    <div className={classes.popupBox}>
      <div className={classes.popupInnerBox}>
        <div>
          <MonetLogo height={12} width="auto" color={colors.primary} />
        </div>
        <h6>{children}</h6>
        <div className={defaultClasses.textCenter}>
          <button onClick={handleClose}>{buttonText}</button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
