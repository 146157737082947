import { ReactComponent as PoweredByIcon } from "components/icons/powered-by-monet.svg";
import React from "react";
import useStyles from "./styles";

const PoweredByMonet: React.FC = () => {
  const classes = useStyles();
  return <PoweredByIcon title="Powered by MONET Money" />;
};

export default PoweredByMonet;
