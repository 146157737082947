import clsx from "clsx";
import lottieBlue from "components/icons/loading-lottie-blue.json";
import lottieWhite from "components/icons/loading-lottie.json";
import { useDefaultStyles } from "components/reusable/defaultStyles";
import React from "react";
import Lottie from "react-lottie";
import useStyles from "./styles";

type WaitingViewProps = {
  useLottieLoader?: boolean;
  size?: "small" | "normal" | "big";
  title?: string;
  lottieColor?: "primary" | "white";
};
const WaitingView: React.FC<WaitingViewProps> = ({ useLottieLoader, size, title = "Loading...", lottieColor = "white" }) => {
  const defaultClasses = useDefaultStyles();
  const classes = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieColor === "primary" ? lottieBlue : lottieWhite,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const maxSize = React.useMemo(() => {
    switch (size) {
      case "small":
        return 44;

      case "normal":
        return 120;

      case "big":
        return 240;

      default:
        return 120;
    }
  }, [size]);
  if (useLottieLoader) {
    return (
      <div className={classes.loadingBox}>
        <Lottie
          style={{
            // overwrite default inline style
            margin: "0px 16px 0px 0px",
            color: "pink",
            fill: "pink",
          }}
          options={defaultOptions}
          height={maxSize}
          width={maxSize}
        />
        <p>{title}</p>
      </div>
    );
  }
  return (
    <div className={clsx(defaultClasses.textCenter, classes.container)}>
      <Lottie
        style={{
          // overwrite default inline style
          margin: "0px 16px 0px 0px",
        }}
        options={defaultOptions}
        height={maxSize}
        width={maxSize}
      />
    </div>
  );
};

export default WaitingView;
