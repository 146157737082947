import { desktopSize } from "components/reusable/defaultStyles";
import colors from "config/colors.json";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  tryAgainButton: {
    paddingTop: 24,
    paddingBottom: 24,
    border: `1px solid ${colors.black}`,
    color: colors.black,
  },
  errorMessage: {
    textAlign: "center",
    maxWidth: 600,
  },
  totalContainer: {
    marginTop: "24px",
    fontSize: 32,

    [desktopSize]: {
      margin: "40px 0",
    },

    "& p": {
      fontSize: 16,
      maxWidth: 500,
    },
  },
  total: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    gap: 24,
    marginBottom: 16,

    "& div": {
      margin: 0,
    },
  },
  item: {
    padding: 8,
    "&:nth-child(2)": {
      borderBottom: `1px solid ${colors.social}38`,
      borderTop: `1px solid ${colors.social}38`,
    },
    "& span": {
      opacity: 0.3,
    },
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    maxWidth: 500,
    gap: 16,
    marginBottom: 56,
    flexWrap: "wrap",
  },
  contentError: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 16,

    [desktopSize]: {
      padding: 32,
    },
  },
  contentContainer: {
    background: colors.background,
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    width: "100%",
    padding: 32,
    margin: "auto",
  },
  ctaContainer: {
    display: "flex",
    flexDirection: "column-reverse",
    maxWidth: 355,
    margin: "0 auto",

    [desktopSize]: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 42,
      maxWidth: "none",
      margin: "auto",
    },
  },
  supportContainer: {
    textAlign: "center",
    marginTop: 42,
    marginBottom: 16,

    [desktopSize]: {
      textAlign: "left",
    },

    "& p": {
      margin: 0,
    },
  },
});
export default useStyles;
