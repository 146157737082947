import clsx from "clsx";
import { ReactComponent as ErrorIcon } from "components/icons/error.svg";
import PageHeader from "components/reusable/Layout/PageHeader";
import { useDefaultStyles } from "components/reusable/defaultStyles";
import colors from "config/colors.json";
import useCheckoutProvider from "contexts/CheckoutContext";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { formatPayoutId } from "shared/logic/payouts";
import { getContactMonetLink } from "utils/PayoutUtils";
import { isPageInIframe, returnToAgency } from "utils/general";
import useStyles from "./styles";

type CheckoutFailedProps = {
  onFailedAction(): void;
};

const CheckoutFailed: React.FC<CheckoutFailedProps> = ({ onFailedAction }) => {
  const defaultClasses = useDefaultStyles();
  const classes = useStyles();
  const { payout } = useCheckoutProvider();

  const search = useLocation().search;
  const payoutId = new URLSearchParams(search).get("payoutId");
  const goToMail = () => {
    window.open(getContactMonetLink(payoutId!));
  };

  return (
    <div className={defaultClasses.wrapper}>
      <div className={classes.contentContainer}>
        <div className={classes.contentError}>
          <div className={classes.headerContainer}>
            <ErrorIcon height={56} width={56} color={colors.error} />
            <PageHeader title=" Unable to complete payment" />
          </div>
          <div className={classes.errorMessage}>
            Please go back to review your payment details and try again. If the problem persists, return to <b>{payout.partner.name}</b> and start again or contact support
          </div>

          <div className={classes.buttonContainer}>
            {payout.partner.returnUrl && isPageInIframe ? null : (
              <button onClick={() => returnToAgency(payout.partner.returnUrl)} className={defaultClasses.button}>
                Return to {payout.partner.name}
              </button>
            )}
            <button onClick={onFailedAction} className={defaultClasses.button}>
              Try Again
            </button>
          </div>

          <div className={defaultClasses.textCenter}>
            <p className={clsx(defaultClasses.textButton, defaultClasses.mb8)} onClick={goToMail}>
              Contact Support
            </p>
            <span className={defaultClasses.textSecondary}>
              Quote the payout ID: <i>{formatPayoutId(payout.payoutId)}</i>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutFailed;
