import { desktopSize } from "components/reusable/defaultStyles";
import colors from "config/colors.json";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  item: {
    padding: 8,
    "&:nth-child(2)": {
      borderBottom: `1px solid ${colors.primary}38`,
      borderTop: `1px solid ${colors.primary}38`,
    },
  },
  totalContainer: {
    color: colors.textWhite,
    background: colors.primary,
    padding: `24px 16px`,
    display: "flex",
    width: "100%",
    justifyContent: "center",
    margin: "16px 0",
    alignItems: "center",

    [desktopSize]: {
      margin: "24px 0",
    },

    "& div": {
      gap: 16,
      width: "100%",
      maxWidth: 350,
    },

    "& div:first-child": {
      justifyContent: "flex-start",
    },
  },
  termsContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 40,
    alignItems: "center",

    "& input": {
      marginBottom: 0,
      display: "none",
    },
    "& .checkItem": {
      width: 16,
      height: 16,
      padding: 1,
      border: `1px solid ${colors.primary}`,
      cursor: "pointer",
    },
    "& .innerCheckItem": {
      display: "none",
      height: "100%",
      background: colors.primary,
    },
    "& input + label": {
      paddingLeft: 16,
      fontSize: 12,
      cursor: "pointer",
      "-webkit-user-select": "none",
      "-moz-user-select": "none",
      " -ms-user-select": "none",
    },

    "& .termsChecked": {
      display: "block",
    },
    "& span": {
      fontSize: "1em",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 56,
  },
  container: {
    display: "block",
    width: "100%",
  },
  content: {
    width: "100%",
    padding: 32,
    margin: "auto",

    "&:first-child": {
      paddingBottom: 0,
    },
    "&:last-child": {
      padding: "0 32px",
    },
  },
  summaryHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap",
    marginBottom: 16,
    columnGap: 16,

    [desktopSize]: {
      marginBottom: 42,
    },
  },
});
export default useStyles;
