import clsx from "clsx";
import { useDefaultStyles } from "components/reusable/defaultStyles";
import * as React from "react";
import useStyles from "./styles";

type StepperProps = {
  hasError?: boolean;
  currentIndex: number;
  containerClass?: string;
  steps: { label: string; className?: string }[];
};

type ErrorBarsProps = { show?: boolean; containerClassName?: string };

const ErrorBars: React.FC<ErrorBarsProps> = ({ show, containerClassName }) => {
  const defaultClasses = useDefaultStyles();
  const classes = useStyles();
  if (!show) return null;
  return (
    <div className={clsx(defaultClasses.flexRow, classes.errorContainer)}>
      <div />
      <div className={classes.errorBars}>
        <div />
        <div />
      </div>
      <div className={clsx(containerClassName)} />
    </div>
  );
};

const Stepper: React.FC<StepperProps> = ({ currentIndex, steps, hasError, containerClass }) => {
  const defaultClasses = useDefaultStyles();
  const classes = useStyles();

  return (
    <div>
      <div className={clsx(defaultClasses.flexRow, defaultClasses.mb8)} style={{ width: "100%" }}>
        {steps.map((item, index) => (
          <div key={item.label} className={clsx(classes.stepperItem, item.className)}>
            <div className={clsx({ dot: true, [classes.inactiveItem]: currentIndex < index })} />
            {index < steps.length - 1 ? (
              <div
                className={clsx({ bar: true, [classes.inactiveItem]: currentIndex <= index, "bar-addon": index > 0 })}
                style={{
                  background: hasError && index === 0 ? "inherit" : "currentColor",
                }}
              >
                <ErrorBars show={hasError && index === 0} containerClassName={containerClass} />
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <div className={clsx(defaultClasses.flexRow, classes.stepperLabelContainer)}>
        {steps.map((item, index) => (
          <div
            key={item.label}
            className={clsx({
              [classes.stepperLabel]: true,
              [defaultClasses.textCenter]: index > 0 && index < steps.length,
              [classes.inactiveItem]: currentIndex < index,
            })}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
