import clsx from "clsx";
import { useDefaultStyles } from "components/reusable/defaultStyles";
import Popup from "components/reusable/Popup/Popup";
import useCheckoutProvider from "contexts/CheckoutContext";
import * as React from "react";
import useStyles from "./styles";

const WrongNumberText: React.FC = () => {
  const defaultClasses = useDefaultStyles();
  const classes = useStyles();
  const [showPopup, setShowPopup] = React.useState(false);
  const { payout } = useCheckoutProvider();

  return (
    <>
      <span onClick={() => setShowPopup(true)} className={clsx(defaultClasses.body1, classes.wrongNumberInfo)} color="primary">
        <strong>Wrong Number?</strong>
      </span>
      <Popup open={showPopup} handleClose={() => setShowPopup(false)}>
        <>
          This is the number you have registered on <strong>{payout.partner.name}</strong>.<br />
          <br />
          In order to verify with a new number, you will need to update it on <strong>{payout.partner.name}</strong> before getting paid early.
        </>
      </Popup>
    </>
  );
};

export default WrongNumberText;
