import { createUseStyles } from "react-jss";
import { desktopSize } from "../defaultStyles";

const useStyles = createUseStyles({
  container: {
    paddingTop: 48,
    [desktopSize]: {
      paddingTop: 64,
    },
    "& svg": {
      height: 60,
      width: "auto",
      [desktopSize]: {
        height: 80,
      },
    },
  },
});
export default useStyles;
