import { useDefaultStyles } from "components/reusable/defaultStyles";

import useCheckoutProvider from "contexts/CheckoutContext";

import clsx from "clsx";
import LogoContainer from "components/reusable/LogoContainer/LogoContainer";
import WaitingView from "components/reusable/WaitingView/WaitingView";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { CHECKOUT_VERIFY_OTP_PATH } from "routing/paths";
import { countryFlagApi } from "utils/CountryFlags";
import Http from "utils/Http";
import { getCheckoutPageLink } from "utils/PayoutUtils";
import CheckoutLayout from "../../components/reusable/Layout/CheckoutLayout";
import PageHeader from "../../components/reusable/Layout/PageHeader";
import WrongNumberText from "./WrongNumberText";
import useStyles from "./styles";

const CheckoutRequestOtp: React.FC = () => {
  const defaultClasses = useDefaultStyles();
  const classes = useStyles();
  const navigate = useNavigate();
  const { payout, setAlertMessage } = useCheckoutProvider();

  const [isSendingCode, setIsSendingCode] = React.useState(false);

  const sendCode = async () => {
    setIsSendingCode(true);
    setAlertMessage("");
    try {
      await Http.post(`checkout/send-otp`, {
        payoutId: payout.payoutId,
      });
      navigate(getCheckoutPageLink(CHECKOUT_VERIFY_OTP_PATH), { state: { payoutId: payout.payoutId! } });

      setIsSendingCode(false);
    } catch (error) {
      setAlertMessage("📱 Sorry, sending verification code failed");
      setIsSendingCode(false);
    }
  };

  return (
    <CheckoutLayout
      leftContent={
        <div className={defaultClasses.leftContentContainer}>
          <PageHeader title="Let's get you paid today" />
          <div className={defaultClasses.maxWidth}>
            <p className={defaultClasses.mb40}>In order to get you paid today we need you to verify your phone number, please request a code.</p>
            <div className={defaultClasses.showOnDesktop}>
              <LogoContainer />
            </div>
          </div>
        </div>
      }
      rightContent={
        <div className={defaultClasses.rightContentContainer}>
          <h6 className={clsx(classes.sendCodeLabel)}>Sending code to</h6>
          <div className={classes.phoneContainer}>
            <img alt="phone_country_flag" src={countryFlagApi(payout.phoneNumber?.countryCode || "")} />
            <span>{payout.phoneNumber?.number?.split("-")[0]}</span>
            <span>
              ******* <strong>{payout.phoneNumber?.number.split("-")[1]}</strong>
            </span>
          </div>
          <WrongNumberText />
          {isSendingCode ? (
            <WaitingView useLottieLoader size="small" />
          ) : (
            <button onClick={sendCode} className={defaultClasses.button}>
              {isSendingCode ? "Sending" : "Send"} Code
            </button>
          )}
        </div>
      }
    />
  );
};

export default CheckoutRequestOtp;
