import clsx from "clsx";
import PoweredByMonet from "components/reusable/PoweredByMonet/PoweredByMonet";
import useCheckoutProvider from "contexts/CheckoutContext";
import * as React from "react";
import useStyles from "./styles";

type LogoContainerProps = {
  className?: string;
  logoSize?: "small" | "large" | "xlarge";
  hideMonetLogo?: boolean;
};

const LogoContainer: React.FC<LogoContainerProps> = ({ className, logoSize = "small", hideMonetLogo }) => {
  const classes = useStyles();
  const { payout } = useCheckoutProvider();

  const logoSizeClass = React.useMemo(() => {
    if (logoSize === "large") {
      return classes.large;
    }
    if (logoSize === "xlarge") {
      return classes.xlarge;
    }
    return classes.small;
  }, [logoSize]);

  return (
    <div className={clsx(classes.logoWrapper, className)}>
      {payout.partner.logo && (
        <div className={clsx(classes.logoContainer, logoSizeClass)}>
          <img src={payout.partner.logo} alt={`${payout.partner.name}`} />
        </div>
      )}
      {!hideMonetLogo && (
        <div className={clsx(classes.logoContainer, logoSizeClass)}>
          <PoweredByMonet />
        </div>
      )}
    </div>
  );
};

export default LogoContainer;
