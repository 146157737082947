import colors from "config/colors.json";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  stepperItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    color: colors.primary,

    // Last dot
    "&:last-child": {
      justifyContent: "flex-start",
      width: "20px",
      opacity: 0.3,
    },
    // Last bar
    "&:nth-child(3)": {
      "& .bar": {
        opacity: 0.1,
      },
    },
    "& .dot": {
      width: 14,
      height: 14,
      background: "currentColor",
      borderRadius: "50%",
    },
    "& .bar": {
      width: "calc(100% - 15px)",
      height: 4,
      background: "currentColor",
      borderRadius: 2,
    },
    "& .bar-addon": {
      marginLeft: 10,
    },
  },
  stepperLabelContainer: {
    alignItems: "start",
  },
  stepperLabel: {
    fontSize: 13,
    "&:last-child": {
      textAlign: "right",
    },
  },
  inactiveItem: {
    opacity: 0.4,
  },
  errorBars: {
    display: "flex",
    width: "20%",
    height: "100%",
    borderRadius: 5,
    "& div:nth-child(2)": {
      marginLeft: -12,
    },
    "& div": {
      borderRadius: 5,
      width: 20,
      height: "100%",
      background: colors.error,
      transform: "rotate(120deg)",
    },
  },
  errorContainer: {
    justifyContent: "flex-start",
    height: "100%",
    "& > div:nth-child(1)": {
      width: "20%",
      height: "100%",
      backgroundColor: colors.error,
      borderRadius: 3,
      marginLeft: -4,
    },
    "& > div:nth-child(3)": {
      width: "100%",
      height: "100%",
      borderRadius: 3,
      background: "currentColor",
    },
  },
});
export default useStyles;
