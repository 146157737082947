import { desktopSize } from "components/reusable/defaultStyles";
import colors from "config/colors.json";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  inputSubText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& :first-child": {
      maxWidth: "55%",
    },
  },
  otpContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 16,
    marginBottom: 8,
    gap: 4,
  },
  otpInput: {
    // Need important to overwrite width
    width: "40px !important",
    height: 56,
    textAlign: "center",
    background: "transparent",
    fontSize: "2rem",
    padding: 0,
    border: `1px solid ${colors.primary}`,
  },
  otpError: {
    color: colors.error,
    border: `1px solid ${colors.error}`,
  },
  timerRunning: {
    opacity: 0.3,
  },
  content: {
    marginBottom: 42,

    [desktopSize]: {
      marginBottom: 0,
    },
  },
});
export default useStyles;
