import clsx from "clsx";
import * as React from "react";
import useStyles from "./styles";

type PageHeaderProps = {
  className?: string;
  title: string | React.ReactElement;
};
const PageHeader: React.FC<PageHeaderProps> = ({ className, title }) => {
  const classes = useStyles();

  return <div className={clsx(classes.pageHeader, className)}>{title}</div>;
};

export default PageHeader;
