import { desktopSize } from "components/reusable/defaultStyles";
import colors from "config/colors.json";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  footnote: {
    color: colors.textSecondary,
    marginTop: 8,
    fontSize: 12,
  },
  leftContent: {
    maxWidth: 355,
    display: "flex",
    flexDirection: "column",
  },
  rightContent: {
    display: "flex",
    justifyContent: "center",
  },
  content: {
    marginBottom: 24,
    marginTop: 24,

    [desktopSize]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
});
export default useStyles;
