import { Money } from "@monet-money/money-type";
import clsx from "clsx";
import { ReactComponent as DoneIcon } from "components/icons/square-checkbox.svg";
import CopyText from "components/reusable/CopyText/CopyText";
import PageHeader from "components/reusable/Layout/PageHeader";
import LogoContainer from "components/reusable/LogoContainer/LogoContainer";
import MobileHeroSection from "components/reusable/MobileHeroSection/MobileHeroSection";
import { useDefaultStyles } from "components/reusable/defaultStyles";
import colors from "config/colors.json";
import useCheckoutProvider from "contexts/CheckoutContext";
import * as React from "react";
import { formatPayoutId } from "shared/logic/payouts";
import { getContactMonetLink } from "utils/PayoutUtils";
import { isPageInIframe, returnToAgency } from "utils/general";
import Stepper from "./Stepper/Stepper";
import useStyles from "./styles";

const steps = [
  {
    label: "Requested",
  },
  {
    label: "Final Checks",
  },
  {
    label: "Money Sent",
  },
  {
    label: "Arrived",
  },
];

const CheckoutCompleted: React.FC = () => {
  const defaultClasses = useDefaultStyles();
  const classes = useStyles();
  const { payout } = useCheckoutProvider();

  const goToMail = () => {
    window.open(getContactMonetLink(payout.payoutId!));
  };

  return (
    <div className={defaultClasses.wrapper}>
      <MobileHeroSection />
      <div className={defaultClasses.container}>
        <div className={clsx(classes.content, defaultClasses.containerMaxWidth)}>
          <div className={classes.headerContainer}>
            <DoneIcon height={64} width={64} color={colors.primary} />
            <PageHeader title="All Done" className={defaultClasses.textPrimary} />
          </div>
          <Stepper steps={steps} currentIndex={1} />
          <div className={clsx(defaultClasses.flexColumn, classes.totalContainer)}>
            <div className={classes.total}>
              <div>SENDING:</div>
              <strong>{Money.fromStorageType(payout.feeSettings.earlyPayTotal).format()}</strong>
            </div>
            <p>Your request has been completed, we are carrying out some final fraud checks and will transfer your money within 24 hours.</p>
          </div>
          <div className={clsx(classes.ctaContainer)}>
            <div className={classes.supportContainer}>
              <p className={defaultClasses.textButton} onClick={goToMail}>
                Contact Support
              </p>
              <p className={clsx(defaultClasses.textSecondary, defaultClasses.body2)}>
                For any queries please contact support quoting <CopyText text={formatPayoutId(payout.payoutId!)} />
              </p>
            </div>
            <div className={defaultClasses.rightContentContainer}>
              {payout.partner.returnUrl &&
                (isPageInIframe ? null : (
                  <button onClick={() => returnToAgency(payout.partner.returnUrl)} className={defaultClasses.button}>
                    Return to {payout.partner.name}
                  </button>
                ))}
            </div>
          </div>
          <div className={clsx(defaultClasses.flexRow, defaultClasses.alignCenter)}>
            <LogoContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutCompleted;
