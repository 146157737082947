import { Percentage } from "@monet-money/percentage-type";
import clsx from "clsx";
import LogoContainer from "components/reusable/LogoContainer/LogoContainer";
import { useDefaultStyles } from "components/reusable/defaultStyles";
import useCheckoutProvider from "contexts/CheckoutContext";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { CHECKOUT_SEND_OTP } from "routing/paths";
import { getCheckoutPageLink } from "utils/PayoutUtils";
import CheckoutLayout from "../../components/reusable/Layout/CheckoutLayout";
import PageHeader from "../../components/reusable/Layout/PageHeader";
import useStyles from "./styles";

const LandingPage: React.FC = () => {
  const classes = useStyles();
  const defaultClasses = useDefaultStyles();
  const navigate = useNavigate();
  const { payout } = useCheckoutProvider();

  return (
    <CheckoutLayout
      leftContent={
        <div className={defaultClasses.leftContentContainer}>
          <PageHeader title="Welcome!" />
          <p className={clsx(defaultClasses.maxWidth, classes.content)}>
            <b>{payout.partner.name}</b> has partnered with MONET to offer faster payouts to our talent.
            <br />
            <br />
            Click continue to confirm your details and get paid within 24 hours.
          </p>
          <div className={clsx(defaultClasses.flexColumn, defaultClasses.maxWidth)}>
            <button onClick={() => navigate(getCheckoutPageLink(CHECKOUT_SEND_OTP))} className={defaultClasses.button}>
              Continue to Verify
            </button>
            <div className={classes.footnote}>A fee of {Percentage.fromStorageValue(payout.feeSettings.fee).format()} will be subtracted from your payment to use EarlyPay</div>
          </div>
        </div>
      }
      rightContent={
        <div className={defaultClasses.showOnDesktop}>
          <div className={defaultClasses.rightContentContainer}>
            <LogoContainer logoSize="large" />
          </div>
        </div>
      }
    />
  );
};

export default LandingPage;
