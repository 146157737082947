import clsx from "clsx";
import { useDefaultStyles } from "components/reusable/defaultStyles";
import React from "react";

type InfoBoxProps = { message?: string; subMessage?: string | React.ReactElement; className?: string; headerClassName?: string };
const InfoBox: React.FC<InfoBoxProps> = ({ message = "Page not found", subMessage, className, headerClassName }) => {
  const defaultClasses = useDefaultStyles();

  return (
    <div className={clsx(defaultClasses.textCenter, className)}>
      <h2 className={headerClassName}>{message}</h2>
      {subMessage}
    </div>
  );
};

export default InfoBox;
