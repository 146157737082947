import { desktopSize } from "components/reusable/defaultStyles";
import colors from "config/colors.json";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  pageHeader: {
    lineHeight: 1.125,
    fontWeight: 600,
    fontSize: 26,
    marginBottom: 16,

    [desktopSize]: {
      fontSize: 48,
    },
  },

  infoBox: {
    background: colors.background,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    padding: 24,

    "& h2": {
      margin: 0,
      marginBottom: 16,
    },

    "& button": {
      marginTop: 0,
    },
  },

  layout: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: colors.primaryDark,

    [desktopSize]: {
      padding: 60,
      background: colors.background,
    },
  },

  mobileLogoWrapper: {
    marginTop: "auto",

    [desktopSize]: {
      display: "none",
    },
  },
});
export default useStyles;
