import InfoBox from "components/reusable/InfoBox/InfoBox";
import { useDefaultStyles } from "components/reusable/defaultStyles";
import emails from "config/emails.json";
import useCheckoutProvider from "contexts/CheckoutContext";
import { CHECKOUT_PATH } from "routing/paths";
import { formatPayoutId } from "shared/logic/payouts";
import { getCheckoutPath, getContactMonetLink, getPayoutIdFromUrl } from "utils/PayoutUtils";

import clsx from "clsx";
import * as React from "react";
import { Navigate } from "react-router-dom";
import CopyText from "../CopyText/CopyText";
import LogoContainer from "../LogoContainer/LogoContainer";
import MobileHeroSection from "../MobileHeroSection/MobileHeroSection";
import useStyles from "./styles";

type CheckoutLayoutProps = {
  leftContent: React.ReactElement;
  rightContent: React.ReactElement;
};

const CheckoutLayout: React.FC<CheckoutLayoutProps> = ({ leftContent, rightContent }) => {
  const defaultClasses = useDefaultStyles();
  const classes = useStyles();
  const { payout } = useCheckoutProvider();

  const payoutId = payout?.payoutId || getPayoutIdFromUrl;

  const getPayoutStatus = React.useMemo(() => {
    if (payout.payoutStatus) {
      return payout.payoutStatus;
    }
    if (!payout.payoutId) return "EXPIRED";
    return "";
  }, [payout]);

  const getPayoutStatusList = React.useMemo(() => {
    switch (getPayoutStatus) {
      case "BENEFICIARY_ACCEPTED":
        return {
          title: "Payout Already Accepted",
          body: "This payout has already been accepted.",
        };
      case "MONET_ACCEPTED":
        return {
          title: "Payment Approved",
          body: "The payment has been approved and is awaiting payout. This should be completed within 24 hours",
        };
      case "MONET_REJECTED":
        return {
          title: "Payment declined",
          body: "Your EarlyPay request has been declined. This could be for a number of reasons, for example failing fraud checks.",
        };
      case "BENEFICIARY_PAID":
      case "SETTLED":
        return {
          title: "Payment Already Completed",
          body: "This payment has already been completed.",
        };
      case "EXPIRED":
        return {
          title: "Payout Expired",
          body: "This payout has expired and is no longer available",
        };
      default:
        return {
          title: "Something went wrong",
          body: "There seem to be incorrect details attached to this payout",
        };
    }
  }, [getPayoutStatus]);

  const goToMail = () => {
    window.open(getContactMonetLink(payout.payoutId! || getPayoutIdFromUrl));
  };

  if (!payout.payoutId && location.pathname !== CHECKOUT_PATH) {
    return <Navigate to={getCheckoutPath()} />;
  }

  if (getPayoutStatus !== "CREATED") {
    return (
      <InfoBox
        className={classes.infoBox}
        message={getPayoutStatusList.title}
        subMessage={
          <div>
            <h6 className={defaultClasses.mb16}>{getPayoutStatusList.body}</h6>
            <div className={clsx(defaultClasses.mb16, "subText")}>
              For more information please contact <CopyText text={emails.support} />
              {payoutId && (
                <>
                  <br />
                  quoting this payoutId: <CopyText text={formatPayoutId(payoutId)} />
                </>
              )}
            </div>

            <button className={defaultClasses.button} onClick={goToMail}>
              Contact MONET
            </button>
          </div>
        }
      />
    );
  }
  return (
    <div className={clsx(classes.layout)}>
      <MobileHeroSection />
      <div className={clsx(defaultClasses.container, defaultClasses.containerMaxWidth, defaultClasses.p32)}>
        <div className={defaultClasses.flexContainer}>
          {leftContent}
          {rightContent}
          <div className={classes.mobileLogoWrapper}>
            <LogoContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutLayout;
