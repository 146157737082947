import useCheckoutProvider from "contexts/CheckoutContext";
import * as React from "react";
import LogoContainer from "../LogoContainer/LogoContainer";
import useStyles from "./styles";

const MobileHeroSection: React.FC = () => {
  const classes = useStyles();
  const { payout } = useCheckoutProvider();

  return (
    <div className={classes.mobileHero}>
      <span>
        Hi,
        <br /> {payout.payee.firstName}
      </span>
      <LogoContainer logoSize="xlarge" hideMonetLogo={true} />
    </div>
  );
};

export default MobileHeroSection;
