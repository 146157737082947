import colors from "config/colors.json";
import { createUseStyles } from "react-jss";

export const desktopSize = "@media (min-width: 960px)";

export const useDefaultStyles = createUseStyles(() => ({
  body1: {
    fontSize: ".875rem",
    letterSpacing: "0.01786em",
    lineHeight: 1.4286,
  },

  body2: {
    fontSize: ".75rem",
    letterSpacing: "0.0334em",
    lineHeight: 1.5,
  },

  button: {
    width: "100%",
    height: 65,
    cursor: "pointer",
    flex: 1,
    background: colors.primary,
    padding: "24px 0px",
    color: colors.textWhite,
    transition: "background 0.25s",
    border: "none",
    fontWeight: 600,
    textTransform: "uppercase",
    marginTop: 16,
    minWidth: 200,

    "&:hover": {
      background: colors.primary + "b3",
    },

    "&:disabled": {
      pointerEvents: "none",
      background: colors.primary + "b3",
    },
  },
  primaryLink: {
    color: colors.primary,

    "&:hover": {
      cursor: "pointer",
    },
  },
  textPrimary: {
    color: colors.primary,
  },
  textSecondary: {
    color: colors.textSecondary,
  },

  textButton: {
    fontSize: "0.75em",
    color: colors.primary,
    cursor: "pointer",
    textDecoration: "underline",

    "&:disabled": {
      color: colors.lightGrey,
      pointerEvents: "none",
    },
  },

  mb8: {
    marginBottom: 8,
  },
  mb16: {
    marginBottom: 16,
  },
  mb24: {
    marginBottom: 24,
  },
  mb40: {
    marginBottom: 40,
  },
  p24: {
    padding: 24,
  },
  p32: {
    padding: 32,
  },
  fullWidth: {
    width: "100%",
  },

  /**
   * Input
   */

  input: {
    width: "100%",
  },

  textCenter: {
    textAlign: "center",
  },

  textLightWeight: {
    fontWeight: 300,
  },

  flexContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    maxWidth: 355,
    width: "100%",
    height: "100%",
    gap: 16,
    margin: "auto",

    [desktopSize]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      maxWidth: "none",
    },
  },

  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  alignCenter: {
    justifyContent: "center",
    alignItems: "center",
  },

  showOnMobile: {
    display: "block",
    [desktopSize]: {
      display: "none",
    },
  },
  showOnDesktop: {
    display: "none",
    [desktopSize]: {
      display: "block",
      "&.inline": {
        display: "inline-block",
      },
    },
  },
  textUnderline: {
    textDecoration: "underline",
  },
  container: {
    display: "block",
    background: colors.background,
    width: "100%",
    height: "100%",
    borderRadius: "25px 25px 0 0",
    overflow: "auto",

    [desktopSize]: {
      flexDirection: "column",
      borderRadius: 0,
      height: "auto",
    },
  },
  wrapper: {
    backgroundColor: colors.primaryDark,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    [desktopSize]: {
      backgroundColor: colors.background,
    },
  },

  rightContentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",

    [desktopSize]: {
      maxWidth: 300,
    },
  },

  leftContentContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  containerMaxWidth: {
    maxWidth: 1200,
  },
  maxWidth: {
    width: "100%",
    maxWidth: 355,
  },
}));
