import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  logoWrapper: {
    display: "flex",
    gap: 15,

    "& img, svg": {
      width: "100%",
    },
  },
  logoContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyItems: "center",
    overflow: "hidden",
  },
  small: {
    maxWidth: 75,
    maxHeight: 75,
  },
  large: {
    maxWidth: 95,
    maxHeight: 95,
  },
  xlarge: {
    maxWidth: 150,
    maxHeight: 150,
  },
});
export default useStyles;
