import colors from "config/colors.json";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  content: {
    maxWidth: 300,
  },
  accountBox: {
    display: "flex",
    gap: 16,
  },
  inputBox: {
    textAlign: "center",
    padding: 16,
    fontWeight: 600,
    border: `1px solid ${colors.primary}38`,
    backgroundColor: colors.lightGrey,
    marginBottom: 16,
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,

    "& span": {
      marginBottom: 4,
      fontWeight: 600,
    },
  },
  sortCode: {
    maxWidth: "fit-content",
  },
});

export default useStyles;
