import InfoBox from "components/reusable/InfoBox/InfoBox";
import useCheckoutProvider from "contexts/CheckoutContext";
import React from "react";
import { Route, Routes } from "react-router-dom";
import CheckoutBankDetails from "views/CheckoutBankDetails/CheckoutBankDetails";
import LandingPage from "views/CheckoutLandingPage/CheckoutLandingPage";
import CheckoutOTPVerification from "views/CheckoutOTPVerification/CheckoutOTPVerification";
import CheckoutSummary from "views/CheckoutSummary/CheckoutSummary";
import CheckoutRequestOtp from "../views/CheckoutRequestOtp/CheckoutRequestOtp";
import { CHECKOUT_BANK_DETAILS_PATH, CHECKOUT_PATH, CHECKOUT_SEND_OTP, CHECKOUT_SUMMARY_PATH, CHECKOUT_VERIFY_OTP_PATH } from "./paths";

const AppRoutes: React.FC = () => {
  const { payout } = useCheckoutProvider();

  return (
    <Routes>
      <Route path={CHECKOUT_VERIFY_OTP_PATH} element={<CheckoutOTPVerification />} />
      <Route path={CHECKOUT_SUMMARY_PATH} element={<CheckoutSummary />} />
      <Route path={CHECKOUT_BANK_DETAILS_PATH} element={<CheckoutBankDetails />} />
      <Route path={CHECKOUT_SEND_OTP} element={<CheckoutRequestOtp />} />
      <Route path={CHECKOUT_PATH} element={payout.partner.showLanding ? <LandingPage /> : <CheckoutRequestOtp />} />
      <Route path="*" element={<InfoBox />} />
    </Routes>
  );
};

export default AppRoutes;
